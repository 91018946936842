<template>
<div>
    <v-dialog v-model="openModal" persistent max-width="800">
        <v-card>
            <v-card-title class="headline">¿Esta seguro que desea inhabilitar este registro?</v-card-title>
            <v-card-text>Realizada esta acción el registro quedara inhabilitado.</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :loading="btnModalInhabilitar" outlined="" @click="openModal = false">
                    <v-icon left="">mdi-backspace</v-icon> Cancelar
                </v-btn>
                <v-btn color="success" :loading="btnModalInhabilitar" @click="inhabilitar()">
                    <v-icon left="">mdi-check-bold</v-icon> Sí, acepto
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            openModal: false,
            idluna_color: 0,
            btnModalInhabilitar: false
        }
    },
    props: {
        objModalInhabilitar: {
            type: Object
        },
    },
    watch: {
        objModalInhabilitar(val) {
            this.openModal = val.openModal,
                this.idluna_color = val.idluna_color
        },
    },

    methods: {
        inhabilitar() {
            this.btnModalInhabilitar = true;
            this.axios({
                method: 'POST',
                url: 'api/panel/lunas-colores/inhabilitar',
                data: {
                    idluna_color: this.idluna_color
                },
            }).then((response) => {

                let status = response.status;
                if (status === 200) {

                    this.openModal = false;

                    this.$toasted.success(response.data.data, {
                        icon: 'mdi-check-bold'
                    });

                    this.$parent.listarRegistros(this.$parent.cmbCantidadRegistros, this.$parent.paginaActual, this.$parent.iptBuscarRegisro);

                }

            }).catch((error) => {

                this.$toasted.error("Ocurrio un error al inhabilitar este registro", {
                    icon: 'mdi-close'
                });

            }).finally(() => {
                this.btnModalInhabilitar = false;
            });
        }
    },

}
</script>
