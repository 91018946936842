<template>
   <v-container fluid>
      <modal-eliminar :objModalEliminar="objModalEliminar"></modal-eliminar>
      <modal-habilitar :objModalHabilitar="objModalHabilitar"></modal-habilitar>
      <modal-inhabilitar :objModalInhabilitar="objModalInhabilitar"></modal-inhabilitar>
      <v-row>
         <v-col cols="12">
            <v-card class="" elevation="" max-width="">
               <v-card-title class="justify-center"> GESTIONAR COLORES </v-card-title>
               <v-divider></v-divider>
               <v-card-text>
                  <v-row>
                     <div class="col-12">
                        <p>
                           <router-link exact="" style="text-decoration: none" :to="{ name: 'inicioPanel' }">Inicio</router-link>
                           /
                           <router-link exact="" style="text-decoration: none" :to="{ name: 'lunasColoresPanel' }">Colores de lunas</router-link>
                        </p>
                     </div>
                     <v-col cols="12" class="text-right">
                        <v-btn :to="{ name: 'lunasColoresCrearPanel' }" color="primary" dark large="">
                           <v-icon left="" large="">mdi-plus-circle</v-icon> NUEVO COLOR
                        </v-btn>
                     </v-col>
                     <v-col cols="12">
                        <v-btn small title="Limpiar filtros" fab dark color="blue-grey" depressed @click="limpiarFiltros()">
                           <v-icon dark>mdi-refresh</v-icon>
                        </v-btn>
                     </v-col>
                     <v-col xl="6" lg="6" md="6" sm="12">
                        <v-select
                           v-model="cmbCantidadRegistros"
                           item-text="texto"
                           item-value="valor"
                           :items="arrCantidadRegistros"
                           label="Cantidad de registros"
                           @change="listarRegistros(cmbCantidadRegistros, null, iptBuscarRegisro)"
                           dense
                           outlined
                        ></v-select>
                     </v-col>
                     <v-col cols="12">
                        <v-text-field
                           label="Buscar registro"
                           prepend-icon="mdi-magnify"
                           clearable
                           type="text"
                           v-model="iptBuscarRegisro"
                           @keyup.enter="listarRegistros(cmbCantidadRegistros, null, iptBuscarRegisro)"
                           @click:clear="listarRegistros(cmbCantidadRegistros, null, null)"
                           @keyup.delete="iptBuscarRegisro === '' ? listarRegistros(cmbCantidadRegistros, null, null) : ''"
                        ></v-text-field>
                     </v-col>
                     <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                        <v-simple-table dense="" fixed-header="" ref="tblListado">
                           <template v-slot:default>
                              <thead class="text-no-wrap">
                                 <tr>
                                    <th>ACCIONES</th>
                                    <th>LUNA</th>
                                    <th>NOMBRE</th>
                                    <th>IMAGEN</th>
                                    <th>POSICIÓN</th>
                                    <th>ESTADO</th>
                                 </tr>
                              </thead>
                              <tbody class="text-no-wrap">
                                 <tr v-for="item in arrRegistrosTabla" :key="item.idluna_color">
                                    <td>
                                       <v-menu offset-y>
                                          <template v-slot:activator="{ on, attrs }">
                                             <v-btn small dark="" depressed="" color="cyan" v-bind="attrs" v-on="on">
                                                <v-icon left="">mdi-cursor-default-click</v-icon>
                                                Acciones
                                             </v-btn>
                                          </template>
                                          <v-list>
                                             <v-list-item
                                                :to="{
                                                   name: 'lunasColoresEditarPanel',
                                                   params: { idluna_color: item.idluna_color },
                                                }"
                                             >
                                                <v-list-item-title> <v-icon>mdi-pencil</v-icon> Editar </v-list-item-title>
                                             </v-list-item>
                                             <v-list-item
                                                v-if="item.estado"
                                                @click="
                                                   objModalInhabilitar = {
                                                      idluna_color: item.idluna_color,
                                                      openModal: true,
                                                   }
                                                "
                                             >
                                                <v-list-item-title> <v-icon>mdi-close</v-icon> Inhabilitar </v-list-item-title>
                                             </v-list-item>
                                             <v-list-item
                                                v-else
                                                @click="
                                                   objModalHabilitar = {
                                                      idluna_color: item.idluna_color,
                                                      openModal: true,
                                                   }
                                                "
                                             >
                                                <v-list-item-title> <v-icon>mdi-check</v-icon> Habilitar </v-list-item-title>
                                             </v-list-item>
                                             <v-list-item
                                                @click="
                                                   objModalEliminar = {
                                                      idluna_color: item.idluna_color,
                                                      openModal: true,
                                                   }
                                                "
                                             >
                                                <v-list-item-title> <v-icon>mdi-trash-can</v-icon> Eliminar </v-list-item-title>
                                             </v-list-item>
                                          </v-list>
                                       </v-menu>
                                    </td>
                                    <td v-text="item.luna == null ? 'SIN LUNA' : item.luna.nombre"></td>
                                    <td v-text="item.nombre"></td>
                                    <td>
                                       <v-img contain width="80" height="80" v-if="item.imagen_url != null" :src="item.imagen_url"></v-img>
                                    </td>
                                    <td v-text="item.posicion"></td>
                                    <td>
                                       <v-chip small class="ma-2" color="green" text-color="white" v-if="item.estado"> Habilitado </v-chip>
                                       <v-chip small class="ma-2" color="red" text-color="white" v-else> Inhabilitado </v-chip>
                                    </td>
                                 </tr>
                              </tbody>
                           </template>
                        </v-simple-table>

                        <p>
                           Mostrando del registro <span v-text="primerRegistro"></span> al <span v-text="ultimoRegistro"></span> de un total de
                           <span v-text="totalRegistro"></span> filas
                        </p>
                     </v-col>

                     <v-col cols="12 text-center" v-show="loaderListado">
                        <v-progress-circular indeterminate color="primary" :size="100" :width="7"></v-progress-circular>
                     </v-col>

                     <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                        <v-pagination
                           circle
                           v-model="paginaActual"
                           :length="cantidadPaginas"
                           :total-visible="7"
                           @input="listarRegistros(cmbCantidadRegistros, paginaActual, iptBuscarRegisro)"
                           :disabled="loaderListado"
                        ></v-pagination>
                     </v-col>

                     <v-col cols="12" v-show="alertRegistroNoEcontrado">
                        <v-alert type="error" dense outlined class="text-center"> NO HAY REGISTROS DISPONIBLES PARA MOSTRAR </v-alert>
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
   import modalEliminar from "./Eliminar";
   import modalHabilitar from "./Habilitar";
   import modalInhabilitar from "./Inhabilitar";
   export default {
      components: {
         modalEliminar,
         modalHabilitar,
         modalInhabilitar,
      },
      data() {
         return {
            arrCantidadRegistros: [
               {
                  texto: 10,
                  valor: 10,
               },
               {
                  texto: 30,
                  valor: 30,
               },
               {
                  texto: 50,
                  valor: 50,
               },
               {
                  texto: 100,
                  valor: 100,
               },
               {
                  texto: 500,
                  valor: 500,
               },
            ],
            loaderListado: false,
            alertRegistroNoEcontrado: false,
            cmbCantidadRegistros: 10,
            arrRegistrosTabla: [],
            paginaActual: 1,
            cantidadPaginas: 1,
            primerRegistro: 0,
            ultimoRegistro: 0,
            totalRegistro: 0,
            iptBuscarRegisro: "",
            objModalEliminar: null,
            objModalHabilitar: null,
            objModalInhabilitar: null,
         };
      },

      methods: {
         listarRegistros(cantidadRegistros = null, paginaActual = null, iptBuscarRegisro = null) {
            this.arrRegistrosTabla = [];
            this.loaderListado = true;
            this.alertRegistroNoEcontrado = false;
            this.axios({
               method: "GET",
               url: "api/panel/lunas-colores",
               params: {
                  cantidadRegistros: cantidadRegistros,
                  paginaActual: paginaActual,
                  iptBuscarRegisro: iptBuscarRegisro,
               },
            })
               .then((response) => {
                  let listado = response.data.data;

                  this.arrRegistrosTabla = listado.data;
                  this.paginaActual = listado.current_page;
                  this.cantidadPaginas = listado.last_page;
                  this.primerRegistro = listado.from;
                  this.ultimoRegistro = listado.to;
                  this.totalRegistro = listado.total;

                  if (this.arrRegistrosTabla.length === 0) {
                     this.alertRegistroNoEcontrado = true;
                  } else {
                     this.alertRegistroNoEcontrado = false;
                  }
               })
               .catch((error) => {
                  console.log(error.response);
               })
               .finally(() => {
                  this.loaderListado = false;
                  this.$vuetify.goTo(this.$refs.tblListado);
               });
         },

         limpiarFiltros() {
            this.cmbCantidadRegistros = 10;
            this.iptBuscarRegisro = "";
            this.listarRegistros();
         },
      },

      created() {
         this.listarRegistros();
      },
   };
</script>
